<template>
  <a-menu>
      <a-menu-item data-cy="profilePageButton" key="1" @click="goToProfile">
        <a><i class="list-icon icon-account_circle"></i>
          Your Profile</a>
      </a-menu-item>
    <!-- <a-menu-item key="2">
      <i class="list-icon icon-download_arrow"></i>
      <a>Published Articles</a>
    </a-menu-item> -->
    <!-- <a-menu-item key="3">
      <img
        class="icon list-icon icon-text"
        src="../../assets/icons/text-box.svg"
      />
      <a>Drafts</a>
    </a-menu-item> -->
    <!-- <router-link data-cy="settingsPageButton" v-if="showOnSubscription" :to="`/user/${userId}/settings`"> -->
      <a-menu-item key="4"  v-if="showOnSubscription" @click="redirectToSettings">
        <a><i class="list-icon icon-settings_filled"></i>
          Settings</a>
      </a-menu-item>
    <!-- </router-link> -->
    <a data-cy="logout" @click="logout">
      <a-menu-item key="5">
       <a><img
            class="icon list-icon icon-logout"
            src="../../assets/icons/logout.svg"
          />
          Logout</a>
      </a-menu-item>
    </a>
  </a-menu>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex/dist/vuex.cjs.js";

export default {
  props: {
    showOnSubscription:{
      type: Boolean,
      default: true
    }
  },
  computed:{
    ...mapGetters({
      authUserInformation: 'profile/userInformation',
    }),
    userId(){
      return this.$store.getters['auth/getUserId'];
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
  },
  methods:{
    ...mapActions({
      setSelectedUser: 'profile/setSelectedUser'
    }),
    async logout(){
      try{
        const accessToken = Cookies.get('access_token')
        Cookies.remove("access_token");
        this.$router.replace('/');
        await this.$store.dispatch('auth/logout', accessToken)
        .then(()=>{
          Cookies.remove("refresh_token");
          Cookies.remove("user_id");
          Cookies.remove("interests")
          Cookies.remove("user")
        })
      }
      catch(err){
        console.log(err.response.data);
      }
    },
    goToProfile() {
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`
      });
      this.getUserInformation()
    },
    async getUserInformation() {
      
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    async redirectToSettings(){
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      await this.$store.dispatch("profile/fetchUserProfile");
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/settings`
      });
    }
  }
};
</script>
<style lang="scss">
.ant-dropdown-menu{
  a{
    display: block;
  }
}
.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  font-family: $font-secondary;
  padding: 0.5rem 1rem;
}
.list-icon {
  color: $color-primary;
  font-size: 2.5rem;
  margin-right: 1rem;
  width: 19%;
}
.list-icon.icon-download_arrow {
  transform: rotate(180deg);
}
.list-icon.icon-logout {
  color: #be0000;
  fill: #be0000;
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg)
    brightness(94%) contrast(115%);
  margin-left: 0.3rem;
}
.list-icon.icon-text {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(246deg)
    brightness(87%) contrast(156%);
}
.logout-btn {
  color: #be0000 !important;
}
</style>