<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="drawerVisibility"
    wrapClassName="pdb-notification"
    width="53rem"
    title="Alerts"
    @close="$emit('close-drawer')"
  >
    <div class="pdb-notification__body">
      <a-tabs @change="tabChange" v-model:activeKey="activeKey">
        <a-tab-pane key="New Releases">
          <template #tab>
            <span data-cy="drawerGlobalDevelopingTab" class="tabs-name">
              New Releases
            </span>
          </template>
        
          <div class="scrollbar-content">
          <div v-if="loading">
            <a-skeleton
            v-for="index in 2"
            :key="index"
            class="features-card-skeleton"
            active
            :title="{ width: '90%' }"
            :paragraph="{ rows: 0, }"
            />
          </div>
          <template v-else>
            <div
              v-for="feature in glocalFeatures"
              :key="feature.id"
              class="notification-card"
            >
              <div class="notification-card__header">
                <p>new</p>
                <h4>
                  {{ getMonthName(feature.created_at.substring(5, 7)) }}
                  {{ feature.created_at.substring(0, 4) }}
                </h4>
              </div>
              <div class="notification-card__body">
                <div class="title">
                  <h2>{{ feature.title }}</h2>
                </div>
                <div class="dec">
                  <p>
                    {{ feature.description }}
                  </p>
                </div>
                <!-- <div class="image">
            <img src="@/assets/images/olampic.jpg" alt="" />
          </div> -->
                <!-- <div class="link">
            <a>Get the plugin now <i class="icon icon-forward"></i></a>
          </div> -->
              </div>
              <div class="notification-card__footer">
                <div class="feedback-section">
                  <input
                    v-model="feedbackText[feature.id]"
                    type="text"
                    :key="feature.id"
                    placeholder="Send us your feedback"
                  />
                  <a-button
                    :submitreq="submitreq[feature.id]"
                    @click="submitFeedback(feature.id)"
                    >Submit</a-button
                  >
                </div>
                <p v-if="hasError[feature.id]" class="error">
                  Please write your feedback to submit
                </p>
              </div>
            </div>
          </template>
          </div>
        </a-tab-pane>

        <a-tab-pane key="Notifications">
          <template #tab>
            <span class="tabs-name">
              <span data-cy="notificationTabLabel">Notifications</span>
              <div class="tabs-notification" v-if="notificationsCount">
                <span v-if="notificationsCount <= 9">{{ notificationsCount }}</span>
                <span v-else>9+</span>
              </div>
            </span>
          </template>
          <div class="scrollbar-content">
            <div v-if="skeletonLoading">
              <skeleton-loader
                v-for="index in 10"
                :key="index"
              ></skeleton-loader>
            </div>
            <p data-cy="noNotification" v-else-if="shareNotifications.length === 0" class="not-found">
              <span data-cy="noNotificationLabel">No notifications found</span>
            </p>
            <div v-else>
            <div class="all-btn-container">
              <a-tooltip class="link-tooltip" placement="topLeft">
                <template #title>Mark All As Read</template>
                <button @click="markUserAllNotificationsAsRead()" class="btn">
                  <img src="@/assets/icons/double-tick-icon.svg" alt="Double tick icon">
                </button>
              </a-tooltip>
              <template v-if="checkedNotificationIds.length>0">
                <a-tooltip class="link-tooltip" placement="topLeft">
                  <template #title>Hide Notifications</template>
                  <button @click="hideNotific" class="btn">
                    <img src="@/assets/icons/eye-off-icon-black.png" alt="Hide icon">
                  </button>
                </a-tooltip>
              </template>
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link dropdown" @click.stop>
                  <i class="icon icon-vertical_three-dots"></i>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0">
                      <a @click="selectAllNotifications()">{{ allSelected ? 'Deselect All' : 'Select All' }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <div
              data-cy="notificationList"
              v-for="notifications in shareNotifications"
              :key="notifications.id"
            >
              <article-notifications
                :articleNotifications="notifications"
                :status="notifications.unread"
                :notificationId="notifications.id"
                :checkedNotificationIds="checkedNotificationIds"
                @close-drawer="closeNotificationsDrawer"
                @selectedNotification="selectedNotification"
              ></article-notifications>
            </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <message-toaster 
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
    >
    </message-toaster>
  </a-drawer>
</template>

<script>
import axios from "axios";
import Months from "../../utils/months_name";
// import UserRequest from "./UserRequest.vue";
import ConnectionRequests from "./ConnectionRequests.vue";
import ArticleNotifications from "./ArticleNotifications.vue";
import SkeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue";
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import {mapActions, mapGetters} from "vuex"

export default {
  emits: ["close-drawer"],
  components: {
    // UserRequest,
    SkeletonLoader,
    // ConnectionRequests,
    ArticleNotifications,
    MessageToaster
  },
  props: {
    drawerVisibility: Boolean,
    loading: Boolean
  },
  computed: {
    ...mapGetters({
      profileNotifications:'profile/getNotifications'
    }),
    userRequestsCount(){
      return this.$store.getters['profile/getUserRequestCount'];
    },
    notificationsCount(){
      return this.$store.getters['profile/getNotificationCount'];
    },
    glocalFeatures(){
      return this.$store.getters['article/getGlocalFeatures'];
    },
    shareNotifications() {
      return this.notificationArray
    },
    userRequests() {
      return this.$store.getters['profile/getuserRequests'];
    },
    allSelected() {
      return this.checkedNotificationIds.length === this.shareNotifications.length;
    },
  },
  data() {
    return {
      // features: [],
      feedbackText: {},
      showMessageModal: false,
      hasError: {},
      message: {},
      submitreq: {},
      months: Months,
      activeKey: "New Releases",
      skeletonLoading: true,
      currentDrawerVisibility: false,
      selectedNotificationId:null,
      notificationArray:[],
      checkedNotificationIds: [],
    };
  },
  watch: {
    profileNotifications: {
      handler() { 
        this.notificationArray = this.profileNotifications.map(notification=>{return {...notification, checked:false}})
      },
      immediate:true
    }
  },
  methods: {
    ...mapActions({
      markAllNotificationAsRead: 'profile/markAllNotificationsAsRead',
      getArticleNotificationsCount: 'profile/getArticleNotificationsCount',
      getArticleNotifications:'profile/getArticleNotifications'
    }),
    tabChange(tab){
      if(tab==='Notifications'){
        this.getArticleNotifications()
      }
    },
    closeNotificationsDrawer(){
      this.$emit('close-drawer');
      // this.drawerVisibility = false;
    },
    async getRequest() {
      this.skeletonLoading = true;
      try {
        const response = await this.$store.dispatch("profile/fetchUserRequests");
        if (response.status === 200) {
          // this.userRequests = response.data;
        }
      } catch (err) {
        console.log(err);
      }
      this.skeletonLoading = false;
    },
    async getArticleNotifications() {
      this.skeletonLoading = true;
      try {
        await this.$store.dispatch(
          "profile/getArticleNotifications"
        );
        // getting the updated count of the notifications as well
        await this.getArticleNotificationsCount() 
      } catch (err) {
        console.log(err);
      }
      this.skeletonLoading = false;
    },
    async submitFeedback(featureId) {
      this.hasError = {};
      if (
        this.feedbackText[featureId] == "" ||
        this.feedbackText[featureId] == undefined
      ) {
        this.hasError[featureId] = true;
        return;
      } else {
        this.submitreq[featureId] = true;
        const response = await axios.post("feature-feedback", {
          glocal_features: featureId,
          feedback: this.feedbackText[featureId],
        });
        if (response.status == 201) {
          this.message = {
            title: "Feedback Submitted",
            desc: "Thankyou for your valuable feedback",
            type: "success",
          };
          this.feedbackText[featureId] = "";
        }
        this.submitreq[featureId] = false;
        this.showMessageModal = true;
      }
    },
    getMonthName(month) {
      return this.months[parseInt(month) - 1];
    },
    async markUserAllNotificationsAsRead() {
      await this.markAllNotificationAsRead()
      await this.getArticleNotifications()
      this.checkedNotificationIds = [];
      await this.getArticleNotificationsCount()
    },
    async hideNotific() {
      try {
        const payload={
          notifications: this.checkedNotificationIds,
           public: false
        }
        const response = await this.$store.dispatch(
          "profile/hideNotification",
          payload
        );
        if (response.status === 200) {
          await this.getArticleNotifications()
          this.checkedNotificationIds=[];
        }
      } catch (err) {
        console.log(err);
      }
      // Perform additional actions as needed
    },
    selectedNotification(notificationId) {
      const isChecked = this.checkedNotificationIds.includes(notificationId);

      if (isChecked) {
        this.checkedNotificationIds = this.checkedNotificationIds.filter(id => id !== notificationId);
      } else {
        this.checkedNotificationIds.push(notificationId);
      }
      // this.notificationArray = this.notificationArray.map(notification => { 
      //   if (notification.id === notificationId) { 
      //     return {...notification, checked: !notification.checked}
      //   }
      //   return notification
      // })
     },
     selectAllNotifications() {
      if (this.allSelected) {
        this.checkedNotificationIds = [];
      } else {
        this.checkedNotificationIds = this.shareNotifications.map(n => n.id);
      }
    },
    toggleNotification(id) {
      const index = this.checkedNotificationIds.indexOf(id);
      if (index === -1) {
        this.checkedNotificationIds.push(id);
      } else {
        this.checkedNotificationIds.splice(index, 1);
      }

      // If any notification is deselected, we should reselect all
      if (this.checkedNotificationIds.length < this.shareNotifications.length) {
        this.checkedNotificationIds = this.shareNotifications.map(n => n.id);
      }
    },
  },
};
</script>

<style lang="scss">
.pdb-notification {
  @include reset-css;
  .ant-drawer-wrapper-body {
    overflow: hidden;
    background-color: $color-white;
  }
  .ant-drawer-header {
    padding: 3.3rem 2.5rem 2.4rem !important;
    border: none;
    .ant-drawer-title {
      font-size: 2.4rem;
      font-family: $font-primary-bold;
      opacity: 1;
      color: $color-black;
    }
    .ant-drawer-close {
      width: 2rem;
      height: 2rem;
      line-height: normal;
      right: 2.5rem;
      top: 3.3rem;
      color: $color-black;
      opacity: 1;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  &__header {
    background-color: #eeeeee;
    padding: 0 4rem;
    padding-bottom: 1rem;
    .active {
      font-family: $font-secondary-bold;
      opacity: 1;
    }
  }
  &__body {
    padding: 3rem 0;
    height: 100vh;
    padding-top: 0;
    .features-card-skeleton {
      margin: 2.5rem;
      .ant-skeleton-content {
        .ant-skeleton-title {
          margin: 0;
          height: 20rem;
        }
        .ant-skeleton-paragraph {
          display: none;
        }
      }
    }
    .notification-card {
      background-color: $color-white;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin-bottom: 3rem;
      margin: 2.5rem;
      &__header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        margin-bottom: 1.5rem;
        padding: 2rem;
        padding-bottom: 0;
        p {
          font-size: 1.4rem;
          background-color: #cdcdcd;
          font-family: $font-primary;
          color: $color-black;
          padding: 1.6rem 1.5rem 1.5rem;
          line-height: 0;
          border-radius: 2rem;
          text-transform: uppercase;
          margin-right: 1rem;
        }
        h4 {
          font-size: 1.6rem;
          font-family: $font-secondary;
          opacity: 0.7;
        }
      }
      &__body {
        padding: 0 2rem;
        padding-bottom: 2rem;
        .title {
          margin-bottom: 0.7rem;
          h2 {
            font-size: 2.2rem;
            font-family: $font-secondary-bold;
            line-height: 3rem;
          }
        }
        .dec {
          font-size: 1.6rem;
          font-family: $font-secondary;
          line-height: 2.3rem;
          opacity: 0.8;
        }
        .image {
          width: 100%;
          height: 100%;
          margin: 1rem 0 1.5rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          a {
            font-size: 1.6rem;
            color: $color-primary;
            font-family: $font-secondary;
            opacity: 0.7;
            .icon {
              font-size: 1.3rem;
            }
          }
        }
      }
      &__footer {
        padding: 2rem;
        border-top: 0.22rem solid rgba(187, 187, 187, 0.616);
        background-color: rgba(187, 187, 187, 0.28);
        display: flex;
        flex-direction: column;
        .feedback-section {
          display: flex;
        }
        input {
          padding: 0.8rem 1.5rem;
          font-size: 1.6rem;
          font-family: $font-secondary;
          border: none;
          outline: none;
          width: 100%;
          margin-right: -0.5rem;
        }
        button {
          width: 10rem;
          font-size: 1.6rem;
          font-family: $font-secondary;
          background-color: $color-primary;
          color: $color-white;
          border: none;
          cursor: pointer;
          margin-left: auto;
          padding: 2rem;
          display: flex;
          align-items: center;
          border-start-start-radius: 0 !important;
          border-end-start-radius: 0 !important;
        }
        .error {
          margin: 5px 0 0 5px;
        }
      }
    }
    .notification {
      display: flex;
      align-items: center;
      border-bottom: 0.24rem solid rgba(110, 110, 110, 0.082);
      padding: 1.7rem 0;
      .user-img {
        width: 4rem;
        margin-right: 1rem;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .user-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        line-height: 1rem;
        h4 {
          font-size: 1.6rem;
          font-family: $font-primary-bold;
          line-height: 2.3rem;
          span {
            font-family: $font-primary;
          }
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .ant-tabs-nav-wrap {
      border: none;
      .ant-tabs-nav-scroll {
        border: none;
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 0.3rem;
        }
        &::-webkit-scrollbar-track {
          background-color: $color-white;
          border-radius: 0.8rem;
          height: 0.3rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
        }
        @include respond(phone-x-small) {
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .ant-tabs-nav {
          border: none;
          padding: 0;
          background-color: $color-white;
          width: 100%;
          .ant-tabs-tab {
            padding: 0.6rem 2rem;
            border-radius: 100px;
            font-size: 1.4rem;
            border: 1px solid $color-dark-grey-5;
            font-family: $font-primary;
            color: #434343;
            margin-right: 1.2rem;
            margin-bottom: 0.2rem;
            .tabs-notification {
              position: absolute;
              top: 0.4rem;
              right: -0.8rem;
              width: 1.5rem;
              height: 1.5rem;
              font-size: 0.8rem;
              background-color: $color-primary;
              color: $color-white;
              line-height: 1.5rem;
              border-radius: 100%;
              font-family: $font-primary-regular;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .ant-tabs-tab-active {
            font-family: $font-primary-regular;
            background-color: $color-dark-grey-5;
            color: $color-black;
            opacity: 1;
          }
          .ant-tabs-ink-bar {
            display: none;
            background-color: transparent;
          }
        }
      }
      
    }

    .ant-tabs-top-content {
      .ant-tabs-tabpane {
        background-color: $color-white;
        padding: 0;
        height: 100vh;
        .scrollbar-content {
          height: calc(100vh - 130px);
          overflow-x: hidden;
          overflow-y: auto;
          background-color: $color-white;
          .all-btn-container {
            padding: 1rem 2.5rem 2rem;
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: sticky;
            top: 0;
            z-index: 44;
            gap: 1.3rem;
            .btn,
            .dropdown {
              line-height: normal;
              border: none;
              outline: none;
              font-size: 1.3rem;
              cursor: pointer;
              font-family: $font-primary-medium;
              display: flex;
              align-items: center;
              background-color: #cdcdcd;
              height: 4rem;
              width: 4rem;
              border-radius: 100% !important;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 2.2rem;
                height: auto;
              }
              .icon {
                color: $color-black;
                font-size: 1.5rem
              }
            }
          }
          .connection-card-skeleton {
            margin: 2rem 0 !important;
            padding: 0 4rem;
          }
        }
        .scrollbar-content::-webkit-scrollbar {
          width: 0.5rem;
        }

        .scrollbar-content::-webkit-scrollbar-track {
          background-color: #c8c8c87d;
          border-radius: 0.8rem;
        }

        .scrollbar-content::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 0.8rem;
          height: 5rem;
        }
        .not-found {
          text-align: center;
          padding: 2rem;
          font-size: 1.6rem;
          font-family: $font-primary;
        }
      }
    }
  }

  .pdb-Collaborations-body {
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pdb-Collaborations-body::-webkit-scrollbar {
    width: 0.5rem;
  }

  .pdb-Collaborations-body::-webkit-scrollbar-track {
    background-color: #c8c8c87d;
    border-radius: 0.8rem;
  }

  .pdb-Collaborations-body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }
  .ant-drawer-content {
    background-color: #eeeeee;
  }
  .ant-tabs-bar {
    margin: 0;
    border: none;
    margin-bottom: 1.6rem;
    padding: 0 2.5rem;
    .ant-tabs-tab-arrow-show {
      display: none !important;
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background-color: transparent !important;
    border: none;
    font-size: 1.6rem;
    font-family: $font-secondary-bold;
    font-weight: 500;
    color: $color-black;
    opacity: 0.5;
    padding-right: 3rem !important;
    padding: 0;
  }
  .ant-tabs-nav-animated {
    padding: 0 4rem;
  }

}
</style>
