<template>
  <div @click="openArticle()" class="portfolio-article-card">
    <div class="portfolio-article-card__image">
      
      <img :src="image" alt="">
    </div>
    <div class="portfolio-article-card__content">
      <h1 class="portfolio-article-card__content--title">{{ title }}</h1>
      <div class="portfolio-article-card__content--source">
        <img v-if="sourceLogo" :src="sourceLogo" alt="" />
        <div class="auth-and-date">
          <p><span>By: {{ authorName }}, {{ source }}</span></p>
          <p>Publish date: {{ date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    authorName: String,
    image: String,
    sourceLogo: String,
    source: String,
    id: String,
    date: String,
  },
  computed: {
    searchTopic() {
      const topic = this.$store.getters["discover/getSearchedTopic"];
      return  topic === "" ? "Discover-world-news" : topic.replace(/\s+/g, '-');
    },
  },
  methods: {
    async openArticle() {
        window.scrollTo(0,0);
        this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
        this.$router.push(`/discover/article/${this.searchTopic}/${this.id}`);
        this.emitter.emit("close-portfolio-drawer");
    },
  }
};
</script>

<style lang="scss" scoped>
.portfolio-article-card {
  @include reset-css;
  display: flex;
  cursor: pointer;
  border: 1px solid $color-dark-grey-5;
  margin-bottom: 2.4rem;
  border-radius: 1rem;
  &__image {
    height: 13.1rem;
    width: 21rem;
    border-start-start-radius: 0.8rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-start-start-radius: 0.8rem;
      border-end-start-radius: 0.8rem;
    }
  }
  &__content {
    width: 100%;
    padding: 1.3rem 1.5rem 1.3rem 1.3rem;
    &--title {
      font-size: 1.6rem;
      font-family: $font-primary-bold;
      color: $color-black;
      opacity: 1;
      line-height: 2.2rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 4.4rem;
    }
    &--source {
      display: flex;
      align-items: center;
      margin-top: 2.5rem;
      img {
        width: 3.6rem;
        height: 3.4rem;
        border-radius: 100%;
        object-fit: fill;
        background-color: rgba(128, 128, 128, 0.136);
        margin-right: 1rem;
        margin-bottom: 0.2rem;
      }
      .auth-and-date {
        width: 100%;
        p {
          font-size: 1rem;
          font-family: $font-primary-regular;
          color: $color-black;
          opacity: 0.5;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 0.2rem;
          span {
            opacity: 0.6;
          }
        }
      }
    }
  }
  // &--img {
  //   width: 10rem;
  //   height: 10rem;
  //   margin-right: 1rem;
  //   object-fit: fill;
  // }
  // &__right {
  //   &--title {
  //     font-size: 1.8rem;
  //     font-family: $font-primary-medium;
  //     font-weight: 700;
  //     line-height: 2.4rem;
  //     font-weight: 600;
  //     //   margin-bottom: 0;
  //     padding: 0 1.2rem;
  //     padding-right: 5rem;
  //     overflow: hidden;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2; /* number of lines to show */
  //     line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //     width: 30rem;
  //   }
  //   &--source {
  //     display: flex;
  //     align-items: center;
  //     padding: 2rem 1.2rem 1.7rem;
  //     img {
  //       width: 4rem;
  //       height: 4rem;
  //       border-radius: 100%;
  //       margin-right: 1rem;
  //       object-fit: fill;
  //       @include respond(laptop-large) {
  //         width: 3rem;
  //         height: 3rem;
  //       }
  //     }
  //     &--auth-and-date {
  //       p {
  //         font-size: 1.2rem;
  //         font-family: $font-primary;
  //         line-height: 1.8rem;
  //         color: #b6b6b6;
  //         margin-bottom: 0;
  //         overflow: hidden;
  //         display: -webkit-box;
  //         -webkit-line-clamp: 2; /* number of lines to show */
  //         line-clamp: 2;
  //         -webkit-box-orient: vertical;
  //       }
  //     }
  //   }
  // }
}
</style>
