<template>
  <div class="saved-collection-article">
    <div class="saved-collection-article__header">
      <h1 @click="createNewCollection()">
        <i class="icon icon-add_plus"></i> Add a new collection folder
      </h1>
    </div>
    <div
      v-if="artilcesFolders.length == 0 && !skeletonLoading"
      class="message-results-not-found"
    >
      <p>No collection founds.</p>
    </div>
    <div class="saved-collection-article__body">
      <a-collapse>
        <a-collapse-panel
          v-for="(folders, index) in artilcesFolders"
          :key="index"
          :header="folders.folder_name"
          @click="getSavedArticle(folders.id)"
        >
          <div
            v-if="articleData.length == 0 && !searchingArticles"
            class="message-results-not-found"
          >
            <p>No shared articles</p>
          </div>
          <div v-if="searchingArticles">
            <a-skeleton
              v-for="index in 2"
              :key="index"
              class="portfolio-card-skeleton"
              active
              :paragraph="{
                rows: 3,
              }"
            />
          </div>
          <portfolio-article-card
            v-else
            v-for="article in articleData"
            :key="article.article_data.id"
            :title="article.article_data.title"
            :image="article.article_data.image"
            :source="article.article_data.source"
            :date="article.article_data.pub_date"
            :id="article.article_data.id"
            :authorName="article.article_data.authors"
            :sourceLogo="article.article_data.logo"
          ></portfolio-article-card>
          <template #extra
            ><p class="minimize"></p>
            <p class="plus">+</p></template
          >
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>

 <create-new-collection-modal
  :visibleCollectionModal="visibleCollectionModal"
  @handle-close="visibleCollectionModal = false"
  >
 </create-new-collection-modal>

</template>

<script>
import PortfolioArticleCard from "./PortfolioArticleCard.vue";
import CreateNewCollectionModal from "./CreateNewCollectionModal.vue";

export default {
  components: {
    PortfolioArticleCard,
    CreateNewCollectionModal
  },
  props: {
    skeletonLoading: Boolean,
  },
  data() {
    return {
      folderId: "",
      searchingArticles: false,
      articleData: [],
      visibleCollectionModal: false
    };
  },
  computed: {
    artilcesFolders() {
      return this.$store.getters["article/getArticlesFolders"];
    },
  },
  methods: {
    async getSavedArticle(folderId) {
      this.folderId = folderId;
      try {
        const payload = {
          user_id: this.$route.params.id,
          folder_id: folderId,
        };
        this.searchingArticles = true;
        this.$store.dispatch("article/fetchSavedArticlesList", payload)
          .then((response) => {
            if (response.status === 200) {
              this.articleData = response.data.save_article;
              this.searchingArticles = false;
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    createNewCollection(){
      this.visibleCollectionModal = true;
    }
  },
};
</script>

<style lang="scss">
.saved-collection-article {
  @include reset-css;
  &__header {
    position: sticky;
    top: 0;
    background-color:$color-white;
    z-index: 444;
    padding-bottom: 0.4rem !important;
    h1 {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      color: $color-black;
      display: flex;
      align-items: center;
      width: fit-content;
      line-height: 2.2rem;
      cursor: pointer;
      opacity: 0.9;
      .icon {
        color: $color-primary;
        margin-right: 1.5rem;
      }
    }
  }
  &__body {
    .ant-collapse {
      background-color: transparent;
      border: none;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          padding: 1rem 1.7rem;
          background-color: transparent;
          border: 1px solid $color-dark-grey-5;
          border-radius: 1rem !important;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          color: $color-black;
          margin: 2.4rem 0;
          .anticon {
            display: none;
          }
          .ant-collapse-extra {
            font-family: $font-primary;
            font-size: 2.5rem;
            top: 50%;
            position: absolute;
            right: 1.7rem;
            transform: translateY(-50%);
            .minimize {
              display: none;
              animation: fade1 1s linear;
              height: 2px;
              width: 1.4rem;
              background-color: #1c1b1f;
              margin-top: 0;
            }
            .plus {
              display: block;
              animation: fade2 1s linear;
              color: #1c1b1f;
            }
          }
        }
        .ant-collapse-content {
          border: none;
          padding: 0;
          .ant-collapse-content-box {
            padding: 0;
            .message-results-not-found {
              padding: 2rem 0;
              p {
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                opacity: 0.7;
                text-align: center;
              }
            }
          }
        }
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          .ant-collapse-extra {
            .minimize {
              display: block;
            }
            .plus {
              display: none;
            }
          }
        }
      }
    }
  }
  //   &__body {
  //     border: 1px solid black;
  //     border-radius: 3rem;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: space-between;
  //     padding: 0 3rem;
  //   }
}
@keyframes fade1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
