<template>
  <div>
    <div class="request-card" v-for="userRequest in userRequests.requests" :key="userRequest.id" >
      <div class="user-img">
        <img :src="userRequest.from_user_profile.profile_picture" alt="" />
      </div>
      <div class="user-details">
        <h4>
          <span>{{ userRequest.from_user_profile.full_name }}</span>
          wants to connect with you
        </h4>
        <!-- <h4>{{ userRequest.message }}</h4> -->
        <p>{{ userRequest.human_time }}</p>
        <div v-if="!requestButtonStatus" class="buttons">
          <a-button
            class="btn primary"
            :loading="onAcceptLoader"
            @click="acceptRequest(userRequest.from_user)"
          >
            Accept
          </a-button>
          <a-button
            class="btn secondary"
            :loading="onRejectLoader"
            @click="rejectRequest(userRequest.from_user)"
          >
            Decline
          </a-button>
        </div>
      </div>
    </div>
  </div>
  <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import MessageToaster from '../BaseComponents/MessageToaster.vue';

export default {
  components: {
    MessageToaster
  },
  data() {
    return {
      onAcceptLoader: false,
      onRejectLoader: false,
      showMessageModal: false,
      message: {},
      requestButtonStatus: false,
      requestStatus: "",
    };
  },
  computed: {
    userRequests() {
      return this.$store.getters['profile/getuserRequests'];
    },
    userRequestsCount(){
      return this.$store.getters['profile/getUserRequestCount'];
    },
  },
  methods: {
    async handleRequest(action_type, from_user, successMessage) {
      const payload = { action_type, from_user };
      this[action_type === 'accepted' ? 'onAcceptLoader' : 'onRejectLoader'] = true;

      try {
        const response = await this.$store.dispatch("profile/userRequest", payload);
        if (response.status === 200) {
          this.showMessageModal = true;
          this.message = { title: successMessage, type: "success" };

          const index = this.userRequests.requests.findIndex(val => val.from_user === from_user);
          if (index !== -1) {
            this.userRequests.requests.splice(index, 1);
            this.$store.commit("profile/setRequestCount", (this.userRequestsCount - 1));
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.onAcceptLoader = false,
        this.onRejectLoader = false
      }
    },

    async acceptRequest(from_user) {
      await this.handleRequest('accepted', from_user, "Request Accepted Successfully");
    },

    async rejectRequest(from_user) {
      await this.handleRequest('rejected', from_user, "Request Rejected Successfully");
    },
  },
};
</script>

<style lang="scss" scoped>
.request-card {
  display: flex;
  align-items: flex-start;
  padding: 1.7rem 4rem;
  border-bottom: 0.2rem solid #aeaeae5e;
  .user-img {
    width: 4rem;
    margin-right: 1rem;
    img {
      width: 100%;
      border-radius: 100%;
    }
  }
  .user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    line-height: 1rem;
    h4 {
      font-size: 1.6rem;
      font-family: $font-primary;
      line-height: 2.2rem;
      span {
        font-family: $font-primary-bold;
        margin-right: 0.6rem;
      }
    }
    p {
      font-size: 1rem;
      margin: 0.8rem 0;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
      .btn {
        width: 14.5rem;
        height: 3rem;
        font-size: 1.2rem;
        font-family: $font-secondary;
        border-radius: 0;
        cursor: pointer;
        color: $color-black;
      }
      .primary {
        background-color: $color-primary;
        color: $color-white;
        border: none;
        margin-right: 2rem;
      }
      .secondary {
        border: 0.2rem solid #70707061;
        background-color: $color-white;
      }
    }
    .connect-btn {
      width: 100%;
    }
  }
}
</style>
