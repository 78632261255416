<template>
  <a-skeleton
    active
    :avatar="{ shape: 'square' }"
    :title="{ width: '90%' }"
    :paragraph="{ rows: 1, width: ['50%'] }"
    class="connection-card-skeleton"
  />
</template>
<style lang="scss">
.connection-card-skeleton {
  margin: 2rem 2.5rem !important;
  .ant-skeleton-header {
    padding: 0;
    height: fit-content;
    height: 4rem;
    width: 4rem;
    margin-right: 1.5rem;
    display: block;
    .ant-skeleton-avatar {
      height: 4rem;
      width: 4rem;
      line-height: inherit;
    }
  }
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 1.5rem;
      margin: 0.2rem 0 0.6rem !important;
    }
    .ant-skeleton-paragraph {
      margin: 0 !important;
      li {
        height: 1.5rem;
      }
    }
  }
}
</style>
